"use strict";
const max_cat_gif_id = 63;

const cat_form = document.getElementById('cat_form');
const cat_gif = document.getElementById('cat_gif');

function init_cat_form()
{
	cat_form.elements['cat_imgid_input'].value = generate_cat_image_random_id();
	if(FormData)
	{
		cat_form.addEventListener('formdata', ev => {
			const formdata = ev.formData;
			formdata.set('text', encode_cat_text(formdata.get('text')));
		});
	}
	else
	{
		cat_form.elements['version_input'].value = 0;
	}
}

function generate_cat_gif_random_id()
{
	return Math.floor(Math.random() * max_cat_gif_id);
}

function encode_cat_text(text)
{
	return btoa(encodeURIComponent(text));
}


init_cat_form();
cat_gif.src = '/gif/' + generate_cat_gif_random_id() + '.gif';